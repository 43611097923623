import React from 'react';
import type { NextPage } from 'next';
import NotFound from '@/routes/NotFound';

/**
 * @see https://nextjs.org/docs/advanced-features/custom-error-page
 * @function NotFoundPage Custom 404 page
 */
const NotFoundPage: NextPage = () => {
  return <NotFound />;
};

export default NotFoundPage;
