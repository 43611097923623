import React from 'react';
import Head from 'next/head';
import { containerCx, bodyCx, titleCx, descriptionCx, textCx } from './styles';

/**
 * @function NotFound
 */
const NotFound: React.VFC = () => {
  return (
    <div className={containerCx}>
      <Head>
        <title key="title">404 - Page Not Found</title>
      </Head>
      <div className={bodyCx}>
        <h1 className={titleCx}>404</h1>
        <div className={descriptionCx}>
          <h2 className={textCx}>Page Not Found.</h2>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
